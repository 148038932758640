import React from "react"
import PropTypes from "prop-types"
import { navigate } from "gatsby"
import FilePreviewer from "react-file-previewer"
import { showFiles, getPayloadById } from "../../services/payload"
import { isLoggedIn, getUser, logout } from "../../services/auth"
import CircularProgress from "@material-ui/core/CircularProgress"

import "./showfile.css"

let _this
export class ShowFile extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      filePreviewData: "",
      inFetch: true,
      error: false,
    }
    _this = this
  }

  render() {
    return (
      <>
        {_this.state.error && (
          <div className="error">
            <h2>File Not Found!</h2>
          </div>
        )}

        {_this.state.inFetch && (
          <div className="progress-container">
            <CircularProgress />
          </div>
        )}
        <div className="preview-container">
          {!_this.state.inFetch && _this.state.filePreviewData && (
            <FilePreviewer file={_this.state.filePreviewData} />
          )}
        </div>
      </>
    )
  }

  async componentDidMount() {
    // If the user is not logged in, navigate them to the login page.
    if (!isLoggedIn()) {
      navigate(`/`)
    }

    // If the user is logged in, fetch and show the file.
    _this.fetchFilesToShow()
  }

  async fetchFilesToShow() {
    try {
      const payloadId = _this.props.payloadId

      if (!payloadId) {
        throw new Error("Error : PayloadId Not Found")
      }
      const payloadResult = await getPayloadById({ _id: payloadId })
      const payload = payloadResult.metadata
      console.log("payloadResult", payloadResult)
      if (!payload) {
        throw new Error("Error Fetching payload")
      }
      _this.validateAccess(payload)
      _this.setState({
        fileExtension: payload.meta.fileData,
      })

      //_this.switchSpinner(false)
      const result = await showFiles(_this.props.payloadId)

      if (!result) {
        _this.setState({
          error: true,
        })
        throw new Error("Error Fetching files data")
      }
      const reader = result.body.getReader()

      await _this.setFiledata(reader)
      _this.switchSpinner()
    } catch (error) {
      _this.setState({
        error: true,
      })
      _this.switchSpinner()
    }
  }
  validateAccess(payload) {
    try {
      const { _id, email } = getUser()

      const isOwner = _id === payload.owner
      // console.log('isOwner',isOwner)
      const { sharedWith } = payload
      console.log("sharedWith", sharedWith)
      const hasAccess = sharedWith.find(val => val.email === email)
      console.log("hasAccess", hasAccess)

      if (!isOwner && !hasAccess) {
        logout(navigate(`/`))
      }
    } catch (error) {
      throw error
    }
  }
  switchSpinner() {
    _this.setState({
      inFetch: false,
    })
  }

  // Set file data to blob type
  // Create html code to start file download
  async setFiledata(reader) {
    try {
      if (!reader) {
        throw new Error("Error starting download")
      }

      let chunks = [] // array of received binary chunks (comprises the body)
      while (true) {
        const { done, value } = await reader.read()

        if (done) {
          break
        }
        chunks.push(value)
      }

      const blob = new Blob(chunks)
      // Convert file in base64 format
      const newReader = new FileReader()

      newReader.readAsDataURL(blob)
      const txtFormats = ["doc", "docx", "txt", "json"]

      newReader.onloadend = function() {
        // Encode to base64
        const _b64 = newReader.result
        // Get base64 code
        const b64 = _b64.substring(37, _b64.length)

        const fileExt = _this.state.fileExtension[0]

        const isTextFormat = txtFormats.find(val => {
          return val === fileExt
        })
        let mimeExt = isTextFormat ? "pdf" : fileExt

        const filePreviewData = {
          data: b64,
          mimeType: `application/${mimeExt}`,
          //name: 'sample.pdf' // for download
        }

        _this.setState({
          filePreviewData, //_this.parseToDataImage(b64)
        })
      }
    } catch (error) {
      throw error
    }
  }
}
ShowFile.propTypes = {
  payloadId: PropTypes.string,
}
