import React from "react"
import PropTypes from "prop-types"
import withLocation from "../components/withLocation"
import { ShowFile } from '../components/show-file'

let _this
class ShowFiles extends React.Component {
    constructor(props, ) {
        super(props)
        this.state = {
            payloadId: props.search.id,
        }
        _this = this

    }

    render() {
        return <>
            <ShowFile
                payloadId={_this.state.payloadId}
            />
        </>
    }

}
ShowFiles.propTypes = {
    search: PropTypes.object,
}

//export default ShowFiles
export default withLocation(ShowFiles)